
<i18n locale="th" lang="yaml" >
page.title: "{name} :: เพิ่ม Lot ใหม่"
page.header: "เพิ่ม Lot ใหม่สำหรับ Model {name}"
page.description : "เพิ่ม Lot ใหม่ ที่ใช้งาน Drone Model รุ่น {name}"

create_lot.success : "ทำการเพิ่ม Lot ใหม่ {name} สำหรับ Drone Model {modelName} เรียบร้อย"
create_lot.unauthorize.status : "ไม่สามารถเพิ่ม Lot ใหม่ได้ เนื่องจากสถานะของรุ่นนี้ยังไม่ Active"

create_lot.submit.label : "เพิ่ม Lot ใหม่"
create_lot.confirm.title : "ยืนยันการสร้าง Drone Lot ใหม่"
create_lot.confirm.message : "คุณต้องการสร้าง Drone Lot ใหม่ สำหรับ รุ่นนี้ ?"
</i18n>

<template>
	<div class="page">
		<my-page-header
			:title="$t('page.header',{ name: model.name })"
			:description="$t('page.description',{ name: model.name })" />
		<a-card :bordered="false">
			<DroneLotForm
				ref="formRef"
				:loading="loading"
				mode="create"
				@submit="handleSubmit">
				<template v-slot:submitSlot="{ formLoading }">
					<a-button
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="edit"
						size="large">
						{{$t('create_lot.submit.label')}}
					</a-button>
				</template>
			</DroneLotForm>
		</a-card>

	</div>

</template>

<script>
import axios from "axios"
import PageMixin from '@mixins/PageMixin.vue'
import DroneLotForm from "@components/drone/DroneLotForm.vue"

export default {
	components : {
		DroneLotForm,
	} ,
	mixins : [PageMixin] ,
	props : {
		model : {
			type: null,
			required: true,
			default : () => []
		}
	},
	data() {
		return {
			loading : false,
		}
	} ,
	mounted(){
		this.checkAuthorized()
	} ,
	methods : {
		checkAuthorized() {
			if (this.model.status != 'active') {
				this.$pageError({name : 'error_forbidden'},{message : this.$t("create_lot.unauthorize.status")})
			}
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('create_lot.confirm.title') ,
				content : this.$t('create_lot.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true
					const sendFormData = {...formData,drone_model_id: this.model.id}
					axios.post("/api/drone-lots/create",sendFormData).then((response) => {
						const newLot = response.data.data.drone_lot
						this.$router.push({
							name : 'drone_lot/view',
							params : {id : newLot.id}
						});
						this.$message.success(this.$t('create_lot.success',{"name" : newLot.lot_name,"modelName" : this.model.name}))
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=>{
						this.loading = false;
					});
				}
			});
		}
	}
}
</script>
